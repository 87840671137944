import React,{createContext,useContext,useState} from "react";
import {auth,googleProvider} from '../firebase/firebase'
import {signInWithPopup} from 'firebase/auth';

import { useNavigate } from "react-router-dom";

// creating context
const AuthContext = createContext();

export const AuthProvider = ({children}) =>{
    const [user,setUser]=useState(null);
    const navigate = useNavigate();

    const SignInWithGoogle = async()=>{
        try{
            const result = await signInWithPopup(auth,googleProvider);
            setUser(result.user);
            navigate('/dashboard')
        }catch(err){
            console.error(err)
        }
    };

    const signOut = async()=>{
        try{
            await auth.signOut();
            setUser(null);
            navigate('/')
        }catch(err){
            console.error(err)
        }
    }

    const value = {
        user,SignInWithGoogle,signOut,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth=()=>{
    return useContext(AuthContext);
}