import React from 'react';
import { useAuth } from '../../context/AuthContext';
import './Login.scss';

/*importing asset */
import Google from '../../assets/google.svg';

const Login = () => {
  const { user, SignInWithGoogle} = useAuth();
  console.log(user);
  return (
    <div className='login'>
      <div className='login-box'>
        <div className='logo'>
          Link<span className='col'>Tools</span>
        </div>
        <button 
        className='login-btn'
        onClick={SignInWithGoogle}>
          <img src={Google} alt='google' />
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default Login;
