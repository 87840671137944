import React from 'react';
import { useAuth } from '../../context/AuthContext';
const Dashboard = () => {
    const {signOut}=useAuth();
  return (
    <div>
      Dashboard
      <button onClick={signOut}>SignOut</button>
    </div>
  );
};

export default Dashboard;
