// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth , GoogleAuthProvider} from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBxdvptn4zH-VPlr-FvHi3Klzcxt7RknI0",
  authDomain: "linktools-9f2ec.firebaseapp.com",
  projectId: "linktools-9f2ec",
  storageBucket: "linktools-9f2ec.appspot.com",
  messagingSenderId: "1013572065684",
  appId: "1:1013572065684:web:05c3359525add48c0a5781",
  measurementId: "G-C9HSWHWWD7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// firebase auth
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider(app);

// [x] auth configuaration
// [x] contextapi integration
// [x] backend auth
// [x] ui fix